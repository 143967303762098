import defaults from "./default.config"
import deepMergeObjects from "../assets/helpers/mergeObjects.js"

export default defineI18nConfig(() => (deepMergeObjects(defaults, {
	messages: {
		en: {
			//TODO: Maintain Alphabetical order!!!
			cartTimeoutBody: "Your cart has expired and your cart items were removed from your cart. We hope to see you on the Dancefloor.",
			cartTimeoutTitle: "Uh Oh, Your Cart Expired",
			cartTimeoutWarningTitle: "Warning",
			disabledCheckout: "The pre-sale is now closed. Open sale begins Sept. 17, 2024 at 10am PT. You can still browse the site and build a cart to plan ahead, but you will not be able to place any orders.",
			errorPageTitle: "Oops, Something went wrong.",
			errorPopupDefaultBody: 'Error',
			errorPopupDefaultTitle: 'Error',
			homepageBody_addOns: '',
			homepageBody_checkout: "",
			homepageBody_confirmation: "Thank you for your order.\nYou will receive a order confirmation email shortly.",
			homepageBody_lodging: '',
			homepageBody_merch: 'Snag this year\'s limited edition merchandise',
			homepageBody_tickets: '',
			homepageBody_volunteers: "Insert Text Here",
			homepageHeading_addOns: "ADD ONS",
			homepageHeading_checkout: "CHECKOUT",
			homepageHeading_confirmation: "{name}, you are on your way to Bass Coast.",
			homepageHeading_lodging: "RESERVED SITES AND BELL TENTS",
			homepageHeading_merch: "Merch",
			homepageHeading_tickets: "TICKETS AND EARLY ENTRY",
			homepageHeading_volunteers: "Insert Text Here",
			lodging: "Sites / Tents",
			loginPageBody: "Our ticket site will be live at 10:00 AM (PST) on July 19th 2024.\n\nThe site is only available to system administrators right now.",
			maintenance1: "The Pre-sale is Now Closed",
			maintenance2: "Open Sale begins Sept 17 10am PT",
			maintenanceBtn: "Back to Site",
			orderPlacedPopupTitle: "Thanks for your order",
			seatsIoErrorPopupBody: "Your item is secure in your cart, and your spot is guaranteed. <br/><br/>However, we are unable to save your preferred location at the moment, but we are working to resolve this promptly. You may still proceed to checkout.",
			seatsIoErrorPopupTitle: "Oops",
			siteNotificationMsg: "The pre-sale is now closed. Open sale begins Sept. 17, 2024 at 10am PT.<br>You can still browse the site and build a cart to plan ahead, but you will not be able to place any orders.<br/><br/>If you are seeing this message after 10am PT on Sept. 17, please refresh your browser.",
			tryAgain: "Try Again",
			xMoreMinutes: "{minutes} More Minutes",
		},
		fr: {},
	}
})))
